import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    

    render() {

        document.title = "Trending Stories in India | Top News Article Today | ReviewTimes";
        document.getElementsByTagName("META")[3].content = "Get the latest Trending Stories such as News, Sports, Humor, Movie, Web Series review and everything in between. Stay updated and follow the trends with Reviewtimes.in";
        document.getElementsByTagName("META")[5].content = "Trending Stories in India, Best News stories in india, Popular articles, Trending Blogs in India, Latest news online, Cricket live Score";
        
        return (
            <>

                <div>
                    <script type="application/ld+json">{`
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name" : "ReviewTimes",
                        "url": "https://www.reviewtimes.in/",
                        "logo": "https://www.reviewtimes.in/assets/img/ReviewtimesLogo.png",
                        "sameAs" : [
                        "https://www.facebook.com/reviewtimes",
                        "https://x.com/reviewtimesin",
	                    "https://www.linkedin.com/company/reviewtimes",
                        "https://www.instagram.com/reviewtimesindia",
                        "https://www.pinterest.com/ReviewTimes/",
                        "https://www.youtube.com/@reviewtimesindia"
                        ],
                        "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Sadbari Amrat Puram Near Shri Hans Mandir, Yashoda Nagar, Kanpur, Uttar Pradesh",
                        "addressRegion": "Kolkata",
                        "streetAddress": "ReviewTimes India Pvt Ltd, Sadbari Amrat Puram Near Shri Hans Mandir, Yashoda Nagar, Kanpur",
                        "postalCode": "208011"
                        }
                    `}
                    </script>
                    <script type="application/ld+json">
                        {`
                            "@context": "https://schema.org",
                            "@type": "LocalBusiness",
                            "name" : "reviewtimes.in",
                            "url": "https://www.reviewtimes.in/",
                            "logo": "https://www.reviewtimes.in/assets/img/ReviewtimesLogo.png",
                            "image": "https://www.reviewtimes.in/assets/img/ReviewtimesLogo.png",
                            "description": "Get the latest Trending Stories such as News, Sports, Humor, Movie, Web Series review and everything in between. Stay updated and follow the trends with Reviewtimes.in.",
                            "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Sadbari Amrat Puram Near Shri Hans Mandir",
                            "addressRegion": "Kanpur",
                            "streetAddress": "ReviewTimes India Pvt Ltd, Sadbari Amrat Puram Near Shri Hans Mandir, Yashoda Nagar, Kanpur",
                            "postalCode": "208011"
                            }

                        `}
                    </script>
                    <script type="application/ld+json">
                        {`
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "url": "https://www.reviewtimes.in/",
                            "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.reviewtimes.in/search?&SearchTerm={query}",
                            "query-input": "required name=query"
                            }
                     `}
                    </script>

                    <script>
                    .IndexContent_Box h1, h2, h3, h4, h5, h6 {`
                        margin-bottom: 10px;
                        font-size: 30px;
                    `}
                    </script>  
                    <script type="application/ld+json">
                    {`
                        "@type": "FAQPage",
                        "mainEntity": [{
                        "@type": "Question",
                        "name": "What does ReviewTimes India Do?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                         "text": "ReviewTimes offers the most trending and latest Stories such as health, Entertainment, Travel, Sports, Humor, Live Cricket Score, Environment, Movie Updates, Web Series review and everything in between."
                        }
                        },{
                        "@type": "Question",
                        "name": "What is Reviewtimes India?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Reviewtimes India is an English and Hindi language daily article updated online, founded in 2013 in Kanpur, Uttar Pradesh. ReviewTimes India is one of the best trending stories portal to get the latest news articles every day."
                        }
                    }}]
                `}
                </script>
                </div>

                <div class="modal fade searchModal" id="searchModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <form>
                                <input type="text" class="form-control" placeholder="Search here...." />
                                <button type="submit"><i class="fi fi-rr-search"></i></button>
                            </form>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="ri-close-line"></i></button>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="trending-box-two">
                        <span>Trending index</span>
                        <div class="trending-slider-two swiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide news-card-one">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/trending-1.webp" alt="Image" />
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#l" class="news-cat">Fashion</a>
                                        <h3><a href="#">Fashion Across The Globe: Exploring 15 Trendsetting Destinations</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 03, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="swiper-slide news-card-one">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/trending-2.webp" alt="Image" />
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#l" class="news-cat">Business</a>
                                        <h3><a href="#">Female Hawks Win $10,000 Funding Boost</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 25, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>13 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="swiper-slide news-card-one">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/trending-3.webp" alt="Image" />
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#l" class="news-cat">Lifestyle</a>
                                        <h3><a href="#">Goodwin Must Break Clarkson Hold</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 22, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>12 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="trending-btn">
                                <div class="trending-btn-prev"><img src="assets/img/icons/left-short-arrow.svg" alt="Image" /></div>
                                <div class="trending-btn-next"><img src="assets/img/icons/right-short-arrow.svg" alt="Image" /></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="container">
                    <div class="row featured-news-three">
                        <div class="col-xl-6">
                            <div class="news-card-eleven">
                                <div class="news-card-img">
                                    <img src="assets/img/news/news-75.webp" alt="Image" />
                                </div>
                                <div class="news-card-info">
                                    <a href="#l" class="news-cat">Business</a>
                                    <h3><a href="#">Revolutionizing The Business World: The Power Of Innovation</a></h3>
                                    <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet consectet Null fringilla purus at leo dignissim congue. Mauris elementum accumsan.</p>
                                    <ul class="news-metainfo list-style">
                                        <li class="author">
                                            <span class="author-img">
                                                <img src="assets/img/author/author-thumb-1.webp" alt="Image" />
                                            </span>
                                            <a href="#">James William</a>
                                        </li>
                                        <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                        <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="news-card-thirteen">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-76.webp" alt="Iamge" />
                                            <a href="#" class="news-cat">Fashion</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Dressing Fashion Tips For Any Occasion</a></h3>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 30, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>11 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="news-card-thirteen">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-77.webp" alt="Iamge" />
                                            <a href="#" class="news-cat">Technology</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Disrupting The Future: The Impact Of Technology</a></h3>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 22, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>12 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="news-card-thirteen">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-78.webp" alt="Iamge" />
                                            <a href="#" class="news-cat">Sports</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Capturing Life's Memories: The Power Of Photography</a></h3>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>10 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="news-card-thirteen">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-79.webp" alt="Iamge" />
                                            <a href="#" class="news-cat">Photography</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Cyclist Out Of Giro After Injuring Eye With Cork</a></h3>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="editor-news-three pt-100 pb-75">
                    <div class="container">
                        <div class="section-title-two mb-40">
                            <div class="row align-items-center">
                                <div class="col-md-7"><h2>Worlds new</h2></div>
                                <div class="col-md-5 text-md-end">
                                    <a href="#" class="link-three">View All News<span><img src="assets/img/icons/arrow-right.svg" alt="Iamge" /></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="news-card-thirteen">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-80.webp" alt="Iamge" />
                                        <a href="#" class="news-cat">Lifestyle</a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">Jiraiya Banks Wants To Teach You How To Build A House</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="news-card-thirteen">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-81.webp" alt="Iamge" />
                                        <a href="#" class="news-cat">Photography</a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">The Secret Math Behind Mind Reading Magic Tricks</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="news-card-thirteen">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-82.webp" alt="Iamge" />
                                        <a href="#" class="news-cat">Business</a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">Recovery And Cleanup In Florida After Hurricane Ian</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="news-card-thirteen">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-83.webp" alt="Iamge" />
                                        <a href="#" class="news-cat">Sports</a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">6 Romantic places You Want to Visit with Your Partner</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="news-card-thirteen">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-84.webp" alt="Iamge" />
                                        <a href="#" class="news-cat">Education</a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">Splurge Or Save Last Minute Pampering Gift Ideas</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="news-card-thirteen">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-85.webp" alt="Iamge" />
                                        <a href="#" class="news-cat">Technology</a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">How To Make Your Life Routine More Fun And Eco-friendly</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="selected-news-three pb-100">
                    <div class="container">
                        <div class="section-title-two mb-40">
                            <div class="row align-items-center">
                                <div class="col-md-6"><h2>Selected posts</h2></div>
                                <div class="col-md-6 text-md-end">
                                    <ul class="nav nav-tabs news-tablist-three" role="tablist">
                                        <li class="nav-item">
                                            <button class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_1" type="button" role="tab">Poilitics<i class="flaticon-arrow-right"></i></button>
                                        </li>
                                        <li class="nav-item">
                                            <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab_2" type="button" role="tab">Sports<i class="flaticon-arrow-right"></i></button>
                                        </li>
                                        <li class="nav-item">
                                            <button class="nav-link " data-bs-toggle="tab" data-bs-target="#tab_3" type="button" role="tab">Business<i class="flaticon-arrow-right"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content selected-news-content">
                            <div class="tab-pane fade" id="tab_1" role="tabpanel">
                                <div class="row justify-content-center">
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-7.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">The Political Landscape: Navigating Power And Policy</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-8.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Navigating the Political Sphere: Insights and Analysis</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-9.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Ex-fifa Officials latini Acquitted Of Fraud Charges</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-10.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Is Technology Changing Treatment Of Injuries?</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-11.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Meet The Final Three Teams To Qualify For The 2023 World Cup</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-12.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Cyclist Out Of Giro D'italia After Injuring Eye With Prosecco Cork</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-13.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Qatar World Cup: Fans Must Show Negative Covid-19 Result</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-14.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Politics</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Joe Gibbs Discusses Ty Gibbs Incident At Martinsville</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade active show" id="tab_2" role="tabpanel">
                                <div class="row justify-content-center">
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-90.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Fit For The Field: A Guide To Training & Nutrition For Athletes</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-91.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Queen Misses Games Due To Health Reasons</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-92.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Ex-fifa Officials Platini Acquitted Of Fraud Charges</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-93.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Is Technology Changing Treatment Of Injuries?</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-94.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Meet The Final Three Teams To Qualify For The 2023 World Cup</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-95.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Cyclist Out Of Giro D'italia After Injuring Eye With Prosecco Cork</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-96.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Qatar World Cup: Fans Must Show Negative Covid-19 Result</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-97.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Joe Gibbs Discusses Ty Gibbs Incident At Martinsville</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab_3" role="tabpanel">
                                <div class="row justify-content-center">
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-9.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Ex-fifa Officials latini Acquitted Of Fraud Charges</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-10.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Is Technology Changing Treatment Of Injuries?</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-94.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Meet The Final Three Teams To Qualify For The 2023 World Cup</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-95.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Cyclist Out Of Giro D'italia After Injuring Eye With Prosecco Cork</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-7.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">The Political Landscape: Navigating Power And Policy</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/politics/politics-8.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Navigating the Political Sphere: Insights and Analysis</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-96.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Qatar World Cup: Fans Must Show Negative Covid-19 Result</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                                        <div class="news-card-thirteen">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-97.webp" alt="Iamge" />
                                                <a href="#" class="news-cat">Business</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Joe Gibbs Discusses Ty Gibbs Incident At Martinsville</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 27, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="#" class="btn-three d-block w-100">View All News<i class="flaticon-arrow-right"></i></a>
                    </div>
                </div>


                <div class="popular-news-three pb-100">
                    <div class="container">
                        <div class="row gx-5">
                            <div class="col-lg-8">
                                <div class="section-title-two mb-40">
                                    <div class="row align-items-center">
                                        <div class="col-md-7"><h2>Most Popular</h2></div>
                                        <div class="col-md-5 text-md-end">
                                            <a href="#" class="link-three">View All News<span><img src="assets/img/icons/arrow-right.svg" alt="Iamge" /></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="popular-news-wrap">
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-70.webp" alt="Image" />
                                            <a href="#" class="news-cat">Lifestyle</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Live Your Best Life: Tips For Achieving A Healthy And Fulfilling Lifestyle</a></h3>
                                            <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet conse ctet fringilla purus leo dignissim congue. Mauris elementum accumsan.</p>
                                            <ul class="news-metainfo list-style">
                                                <li class="author">
                                                    <span class="author-img">
                                                        <img src="assets/img/author/author-thumb-1.webp" alt="Image" />
                                                    </span>
                                                    <a href="#">James William</a>
                                                </li>
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-71.webp" alt="Image" />
                                            <a href="#" class="news-cat">Business</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Maximizing Profits: A Guide To Streamlining Your Business Operations</a></h3>
                                            <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet conse ctet fringilla purus leo dignissim congue. Mauris elementum accumsan.</p>
                                            <ul class="news-metainfo list-style">
                                                <li class="author">
                                                    <span class="author-img">
                                                        <img src="assets/img/author/author-thumb-1.webp" alt="Image" />
                                                    </span>
                                                    <a href="#">James William</a>
                                                </li>
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-72.webp" alt="Image" />
                                            <a href="#" class="news-cat">Events</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Making Events Memorable: A Guide To Planning Successful Gatherings</a></h3>
                                            <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet conse ctet fringilla purus leo dignissim congue. Mauris elementum accumsan.</p>
                                            <ul class="news-metainfo list-style">
                                                <li class="author">
                                                    <span class="author-img">
                                                        <img src="assets/img/author/author-thumb-3.webp" alt="Image" />
                                                    </span>
                                                    <a href="#">James William</a>
                                                </li>
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-73.webp" alt="Image" />
                                            <a href="#" class="news-cat">Photography</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Capturing Life's Moments: A Guide to Improving Your Photography Skills</a></h3>
                                            <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet conse ctet fringilla purus leo dignissim congue. Mauris elementum accumsan.</p>
                                            <ul class="news-metainfo list-style">
                                                <li class="author">
                                                    <span class="author-img">
                                                        <img src="assets/img/author/author-thumb-2.webp" alt="Image" />
                                                    </span>
                                                    <a href="#">James William</a>
                                                </li>
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-74.webp" alt="Image" />
                                            <a href="#" class="news-cat">Culture</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Exploring the World's Diversity: A Journey Through Different Cultures</a></h3>
                                            <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet conse ctet fringilla purus leo dignissim congue. Mauris elementum accumsan.</p>
                                            <ul class="news-metainfo list-style">
                                                <li class="author">
                                                    <span class="author-img">
                                                        <img src="assets/img/author/author-thumb-4.webp" alt="Image" />
                                                    </span>
                                                    <a href="#">James William</a>
                                                </li>
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="sidebar">
                                    <div class="sidebar-widget">
                                        <h3 class="sidebar-widget-title">Explore Topics</h3>
                                        <ul class="category-widget list-style">
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Celebration <span>(6)</span></a></li>
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Culture<span>(3)</span></a></li>
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Fashion<span>(2)</span></a></li>
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Inspiration<span>(8)</span></a></li>
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Lifestyle<span>(6)</span></a></li>
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Politics<span>(2)</span></a></li>
                                            <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Trending<span>(4)</span></a></li>
                                        </ul>
                                    </div>
                                    <div class="sidebar-widget-two">
                                        <div class="contact-widget">
                                            <img src="assets/img/contact-bg.svg" alt="Image" class="contact-shape" />
                                            <a href="#" class="logo">
                                                <img class="logo-light" src="assets/img/ReviewtimesLogo.png" alt="Image" />
                                                <img class="logo-dark" src="assets/img/ReviewtimesLogo.png" alt="Image" />
                                            </a>
                                            <p>Mauris mattis auctor cursus. Phasellus iso tellus tellus, imperdiet ut imperdiet eu, noiaculis a sem Donec vehicula luctus nunc in laoreet Aliquam</p>
                                            <ul class="social-profile list-style">
                                                <li><a href="https://www.fb.com/" target="_blank"><i class="flaticon-facebook-1"></i></a></li>
                                                <li><a href="https://www.twitter.com/" target="_blank"><i class="flaticon-twitter-1"></i></a></li>
                                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram-2"></i></a></li>
                                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="sidebar-widget">
                                        <h3 class="sidebar-widget-title">Recommended</h3>
                                        <div class="pp-post-wrap-two">
                                            <div class="news-card-one">
                                                <div class="news-card-img">
                                                    <img src="assets/img/news/news-thumb-4.webp" alt="Image" />
                                                </div>
                                                <div class="news-card-info">
                                                    <h3><a href="#">Bernie Nonummy Pelopai Iatis Eum Litora</a></h3>
                                                    <ul class="news-metainfo list-style">
                                                        <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="news-card-one">
                                                <div class="news-card-img">
                                                    <img src="assets/img/news/news-thumb-5.webp" alt="Image" />
                                                </div>
                                                <div class="news-card-info">
                                                    <h3><a href="#">How Youth Viral Diseases May The Year 2023</a></h3>
                                                    <ul class="news-metainfo list-style">
                                                        <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 23, 2023</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="news-card-one">
                                                <div class="news-card-img">
                                                    <img src="assets/img/news/news-thumb-6.webp" alt="Image" />
                                                </div>
                                                <div class="news-card-info">
                                                    <h3><a href="#">Man Wearing Black Pullover Hoodie To Smoke</a></h3>
                                                    <ul class="news-metainfo list-style">
                                                        <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 14, 2023</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="news-card-one">
                                                <div class="news-card-img">
                                                    <img src="assets/img/news/news-thumb-7.webp" alt="Image" />
                                                </div>
                                                <div class="news-card-info">
                                                    <h3><a href="#">First Prototype Flight Using Kinetic Launch System</a></h3>
                                                    <ul class="news-metainfo list-style">
                                                        <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 07, 2023</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="general-news-three pb-75">
                    <div class="container">
                        <div class="row gx-55 gx-4">
                            <div class="col-xl-4">
                                <div class="sidebar">
                                    <div class="sidebar-widget">
                                        <h3 class="sidebar-widget-title">Celebration</h3>
                                        <div class="featured-widget">
                                            <div class="featured-slider swiper">
                                                <div class="swiper-wrapper">
                                                    <div class="swiper-slide">
                                                        <div class="news-card-one">
                                                            <div class="news-card-img">
                                                                <img src="assets/img/news/news-thumb-1.webp" alt="Image" />
                                                            </div>
                                                            <div class="news-card-info">
                                                                <h3><a href="#">How Youth Viral Diseases May The Year 2023</a></h3>
                                                                <ul class="news-metainfo list-style">
                                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 24, 2023</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="swiper-slide">
                                                        <div class="news-card-one">
                                                            <div class="news-card-img">
                                                                <img src="assets/img/news/news-thumb-2.webp" alt="Image" />
                                                            </div>
                                                            <div class="news-card-info">
                                                                <h3><a href="#">Nintendo Switch Online�s Next Wave of N64 Games</a></h3>
                                                                <ul class="news-metainfo list-style">
                                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 22, 2023</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="swiper-slide">
                                                        <div class="news-card-one">
                                                            <div class="news-card-img">
                                                                <img src="assets/img/news/news-thumb-3.webp" alt="Image" />
                                                            </div>
                                                            <div class="news-card-info">
                                                                <h3><a href="#">5 things We Know About GTA Definitive Edition</a></h3>
                                                                <ul class="news-metainfo list-style">
                                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Mar 14, 2023</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="featured-prev"><i class="flaticon-left-arrow"></i></div>
                                                <div class="featured-next"><i class="flaticon-right-arrow"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sidebar-widget newsletter-widget-two">
                                        <h2>Newsletter</h2>
                                        <h6>Join 70,000 Subscribers</h6>
                                        <form action="#">
                                            <input type="email" placeholder="Email Address" />
                                            <button type="submit">Subscribe<i class="flaticon-right-arrow-1"></i></button>
                                        </form>
                                        <p>By signing up, you agree to our <a href="#">Privacy Policy</a></p>
                                    </div>
                                    <div class="sidebar-widget">
                                        <h3 class="sidebar-widget-title">Popular Tags</h3>
                                        <ul class="tag-list list-style">
                                            <li><a href="#">BUSINESS</a></li>
                                            <li><a href="#">FOOD</a></li>
                                            <li><a href="#">SCIENCE</a></li>
                                            <li><a href="#">LIFESTYLE</a></li>
                                            <li><a href="#">SPORTS</a></li>
                                            <li><a href="#">PHOTO</a></li>
                                            <li><a href="#">TECHNOLOGY</a></li>
                                            <li><a href="#">CONTENT</a></li>
                                            <li><a href="#">FEATURED</a></li>
                                            <li><a href="#">AUDIO</a></li>
                                            <li><a href="#">FASHION</a></li>
                                        </ul>
                                    </div>
                                    <div class="sidebar-widget-two">
                                        <div class="gallery-widget">
                                            <img src="assets/img/ReviewtimesLogo.png" alt="Image" class="logo" />
                                            <img src="assets/img/gallery-bg-2.svg" alt="Image" class="gallery-shape" />
                                            <img src="assets/img/gallery-img.webp" alt="Image" class="gallery-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8">
                                <div class="section-title-two mb-40">
                                    <div class="row align-items-center">
                                        <div class="col-md-7"><h2>General News</h2></div>
                                        <div class="col-md-5 text-md-end">
                                            <a href="#" class="link-three">View All News<span><img src="assets/img/icons/arrow-right.svg" alt="Iamge" /></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="news-card-four">
                                            <img src="assets/img/news/news-65.webp" alt="Image" />
                                            <div class="news-card-info">
                                                <a href="#" class="news-cat">Politics</a>
                                                <h3><a href="#">Empowering The People: The Role Of Politics In Society</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="news-card-two">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-66.webp" alt="Image" />
                                                <a href="#" class="news-cat">Photography</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">The Secret Math Behind Mind Reading Magic Tricks</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="news-card-two">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-67.webp" alt="Image" />
                                                <a href="#" class="news-cat">Sports</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">6 Romantic Places You Want To Visit With Your Partner</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="news-card-two">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-68.webp" alt="Image" />
                                                <a href="#" class="news-cat">Technology</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">The Secret Math Behind Mind Reading Magic Tricks</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="news-card-two">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-69.webp" alt="Image" />
                                                <a href="#" class="news-cat">Culture</a>
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">3 Historical places You Want to Visit with Your Partner</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                                    <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="video-wrap-three pb-75">
                    <div class="container">
                        <div class="section-title-two mb-40">
                            <div class="row align-items-center">
                                <div class="col-md-7"><h2>Featured Video</h2></div>
                                <div class="col-md-5 text-md-end">
                                    <a href="#" class="link-three">View All Featured Video<span><img src="assets/img/icons/arrow-right.svg" alt="Iamge" /></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center gx-5">
                            <div class="col-xl-8">
                                <div class="news-card-eleven">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-64.webp" alt="Image" />
                                        <a class="play-now" href="#quickview-modal" data-bs-toggle="modal">
                                            <i class="flaticon-play-button"></i>
                                        </a>
                                    </div>
                                    <div class="news-card-info">
                                        <h3><a href="#">Apex Legends Season 11 Start Date, Time, & What To Expect</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li class="author">
                                                <span class="author-img">
                                                    <img src="assets/img/author/author-thumb-1.webp" alt="Image" />
                                                </span>
                                                <a href="#">James William</a>
                                            </li>
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                            <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="news-card-three">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-35.webp" alt="Image" />
                                        <a class="play-now" href="#quickview-modal" data-bs-toggle="modal">
                                            <i class="flaticon-play-button"></i>
                                        </a>
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#" class="news-cat">Travel</a>
                                        <h3><a href="#">World Trending Best 10 Website Travel Tips</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="news-card-three">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-36.webp" alt="Image" />
                                        <a class="play-now" href="#quickview-modal" data-bs-toggle="modal">
                                            <i class="flaticon-play-button"></i>
                                        </a>
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#" class="news-cat">Business</a>
                                        <h3><a href="#">How To Find The Right Template For Your Product</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="news-card-three">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-37.webp" alt="Image" />
                                        <a class="play-now" href="#quickview-modal" data-bs-toggle="modal">
                                            <i class="flaticon-play-button"></i>
                                        </a>
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#" class="news-cat">Health</a>
                                        <h3><a href="#">Life Health Continues To Spread Rapidly, Are Many People</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="news-card-three">
                                    <div class="news-card-img">
                                        <img src="assets/img/news/news-3.webp" alt="Image" />
                                        <a class="play-now" href="#quickview-modal" data-bs-toggle="modal">
                                            <i class="flaticon-play-button"></i>
                                        </a>
                                    </div>
                                    <div class="news-card-info">
                                        <a href="#" class="news-cat">Lifestyle</a>
                                        <h3><a href="#">5 Things We Know About Gta Trilogy: Definitive Edition So Far</a></h3>
                                        <ul class="news-metainfo list-style">
                                            <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 03, 2023</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="latest-news-three pb-75">
                    <div class="container">
                        <div class="section-title-two mb-40">
                            <div class="row align-items-center">
                                <div class="col-md-7"><h2>Latest News</h2></div>
                                <div class="col-md-5 text-md-end">
                                    <a href="#" class="link-three">View All News<span><img src="assets/img/icons/arrow-right.svg" alt="Iamge" /></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="row gx-55 gx-5">
                            <div class="col-xl-6">
                                <div class="scrollscreen">
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-9.webp" alt="Image" />
                                            <a href="#" class="news-cat">Lifestyle</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Good Day To Take A Photo With Your Favorite Style</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-10.webp" alt="Image" />
                                            <a href="#" class="news-cat">Fashion</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">I Turned My Home Into A Fortress of Surveillance</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 15, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>10 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-11.webp" alt="Image" />
                                            <a href="#" class="news-cat">Science</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Man Wearing Black Pullover Hoodie To Smoke Light In</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 17, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>8 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-12.webp" alt="Image" />
                                            <a href="#" class="news-cat">Photography</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Recovery And Cleanup In Florida After Hurricane Ian</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 12, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>13 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-13.webp" alt="Image" />
                                            <a href="#" class="news-cat">Business</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Apex Legends Season 11 Starting From August, 2023</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 07, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-14.webp" alt="Image" />
                                            <a href="#" class="news-cat">Travel</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Creative Photography Ideas From Smart Devices</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 05, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>11 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-15.webp" alt="Image" />
                                            <a href="#" class="news-cat">Travel</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">6 Romantic Places You Want To Visit With Your Partner</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 03, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-16.webp" alt="Image" />
                                            <a href="#" class="news-cat">Fashion</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">7 Steps To Get Professional Facial Results At Home</a></h3>
                                            <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 02, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>10 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="latest-news-right">
                                    <div class="news-card-two">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-62.webp" alt="Image" />
                                            <a href="#" class="news-cat">Technology</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">Elijah James: Nashville Photographer Shares Unique Journey</a></h3>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Feb 25, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="news-card-five">
                                        <div class="news-card-img">
                                            <img src="assets/img/news/news-63.webp" alt="Image" />
                                            <a href="#" class="news-cat">Sports</a>
                                        </div>
                                        <div class="news-card-info">
                                            <h3><a href="#">A Complimentary Day At Mandarine The Oriental Stadium</a></h3>
                                            <p>Lorem ipsum or lipsum as it is known is dum text used in laying print, graphic or desi�</p>
                                            <ul class="news-metainfo list-style">
                                                <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                <li><i class="fi fi-rr-clock-three"></i>15 Min Read</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="latest-news-three pb-40">
                    <div class="container">
                        <hr class="mb-40"/>
                        <div class="row gx-55 gx-5">
                            <div class="col-xl-12">
                                <p style={{ textAlign:'justify' }}>ReviewTimes.in is your go-to place for learning the latest top stories in business, lifestyle and more. We also bring you honest reviews about everything from latest gadgets and movies to recently launched web series on popular OTT platforms. Among the many other content you will find on Review Times is everything that is trending in the world of social media. We scour the internet for the latest buzz and bring you the facts after complete verification. Moreover, if you are looking for something funny, we have got your back. Keep reading to know more about what Review Times brings for you! </p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
